// Components
import Sidebar from "../../../components/Sidebar";
import Wrapper from "../../../components/Wrapper";
import Inner from "../../../components/Inner";
import { Grid, Col } from "../../../components/Grid";
import ModalHistorial from "../../../components/Modals/SolicitudEmision/ModalHistorial";
import TitlePageSection from "../../../components/TitlePageSection";
import NotificacionPagina from "../../../components/NotificacionPagina";
import NewButton from "../../../components/NewButton";
import NewFiltersSection from "../../../components/NewFiltersSection";
import { Container } from "./styled";
// Custom Hooks
import useSolicitudEmision from "../../../hooks/useSolicitudEmision";
import useFiltersNew from "../../../hooks/useFiltersNew";
import useHistorialNew from "../../../hooks/useHistorialNew";
import { useDescarga } from "../../../hooks/useDescarga";
import { useAsignacion } from "../../../hooks/useAsignacion";
import { useTable } from "../../../hooks/useTable";
import { useActionButton } from "../../../hooks/useActionButton";
// Filters
import { filtersSolicitudEmision } from "../../../utils/filtersPages";
import { filterSolicitudEmisionInitialValues } from "../../../utils/filtersPages";
// Table
import { solicitudEmisionTable } from "../../../utils/tableData";
import ModalLoading from "../../../components/Modals/General/ModalLoading";
// Icons
import { FiFilter, FiUserCheck, FiX } from "react-icons/fi";
import ModalAsignacion from "../../../components/Modals/SolicitudEmision/ModalAsignacion";
import { useNotificacionesPage } from "../../../hooks/useNotificaciones";
import NewTable from "../../../components/NewTable";
import { useEffect, useState } from "react";
import CardStats from "../../../components/CardStats";
import Divider from "../../../components/Divider";
import { useSingleOpen } from "../../../hooks/useSingleOpen";

const SolicitudEmisionRefactor = () => {
  const [filtersApplied, setFiltersApplied] = useState([]);
  // Storage
  const user = JSON.parse(localStorage.getItem("userInfo"));
  // Modales
  const {
    historial,
    isModalOpen,
    handleModal,
    handleModalHistorial,
    solicitudData,
  } = useHistorialNew();
  const { modal, descargarSolicitud } = useDescarga();
  // Filtros
  const {
    inputValues,
    params,
    handleSearch,
    handleChange,
    handleChangeExternal,
    clearSearch,
  } = useFiltersNew(filterSolicitudEmisionInitialValues);
  // Orden
  const { order, handleTableOrder } = useTable();
  // Solicitud Emisión
  const { data, refreshSolicitudesEmision, loading, pagination } =
    useSolicitudEmision(params, order);
  // Filtros
  const filters = filtersSolicitudEmision(data);
  // Asignación de solicitudes
  const asignacionModal = useAsignacion(refreshSolicitudesEmision);
  // Botones
  const { isActionButtonOpen, handleToggle } = useActionButton();
  // Información para la tabla
  const tableData = solicitudEmisionTable(
    handleModalHistorial,
    descargarSolicitud,
    asignacionModal.seleccionarSolicitud,
    asignacionModal.solicitudesSeleccionadas,
    isActionButtonOpen,
    handleToggle,
    user
  );
  const { isOpen: isFiltersOpen, handleOpen: handleFiltersOpen } = useSingleOpen()

  // Notificaciones
  const { notificaciones, resetNotificaciones, mensaje } =
    useNotificacionesPage(
      ["solicitud-creada", "solicitud-asignada-operador"],
      refreshSolicitudesEmision
    );

  useEffect(() => {
    if (params) {
      let filterArr = [];
      if (params.includes("&")) {
        const filters = params.split("&");
        const filtersDos = filters.map((item) => item.split("="));
        filterArr.push(filtersDos);
        const decodeFilters = filtersDos.map(item => {
          return [item[0], decodeURI(item[1])]
        })
        setFiltersApplied(decodeFilters);
      } else {
        const filters = params.split("=");
        const arr = [filters[0], decodeURI(filters[1])]
        filterArr.push(arr);
        setFiltersApplied([arr]);
      }
    }
  }, [params]);

  const removeFilter = (e, filterName) => {
    handleChangeExternal(e, filterName, '')
  }

  return (
    <>
      <Sidebar active="solicitudEmision" />

      <ModalLoading
        isModalOpen={modal.isModalOpen}
        setIsModalOpen={modal.setIsModalOpen}
        message={modal.modalMessage}
      />

      <ModalAsignacion
        isModalOpen={asignacionModal.modal.isModalOpen}
        setIsModalOpen={asignacionModal.modal.handleModal}
        solicitudes={asignacionModal.solicitudesSeleccionadas}
        removerSolicitud={asignacionModal.removerSolicitud}
        asignarSolicitudes={asignacionModal.asignarSolicitud}
        operadores={data.operadoresIds}
        handleChangeOperador={asignacionModal.handleChangeOperador}
        loading={asignacionModal.loading}
        solicitudesAsignadas={asignacionModal.solicitudesAsignadas}
        operador={asignacionModal.operadorId}
      />

      <ModalHistorial
        isModalOpen={isModalOpen}
        setIsModalOpen={handleModal}
        historial={historial}
        solicitudData={solicitudData}
      />

      <Wrapper>
        <Inner>
          <Container>
            <Grid colGap={21} rowGap={21} narrow={true}>
              <Col desktop={12}>
                <TitlePageSection
                  title="Solicitudes de Emisión"
                  description={
                    "Lista de solicitudes pendientes y rechazadas de emisión"
                  }
                >
                  <NewButton
                    backgroundColor={false}
                    textColor={true}
                    borderColor={true}
                    onClick={() => handleFiltersOpen(!isFiltersOpen)}
                  >
                    <FiFilter /> Filtros
                    {params?.split("=").length > 1 ? (
                      <span className="filtros-aplicados">
                        {params?.split("=").length - 1}
                      </span>
                    ) : null}
                  </NewButton>
                  {(user.id_rol === 1 ||
                    user.id_rol === 13 ||
                    user.id_rol === 7 ||
                    user.id_rol === 6 ||
                    user.id_rol === 14) && (
                    <NewButton
                      backgroundColor={true}
                      textColor={false}
                      borderColor={false}
                      onClick={asignacionModal.modal.handleModal}
                    >
                      <span>
                        <FiUserCheck />
                      </span>
                      Asignar solicitudes{" "}
                      {asignacionModal.solicitudesSeleccionadas.length > 0 ? (
                        <span className="solicitudes-seleccionadas">
                          {asignacionModal.solicitudesSeleccionadas.length}
                        </span>
                      ) : null}
                    </NewButton>
                  )}
                </TitlePageSection>
              </Col>
            </Grid>

            {isFiltersOpen ? (
              <Grid colGap={21} rowGap={21} narrow={true}>
                <Col desktop={12}>
                  <NewFiltersSection
                    section="solicitudes"
                    inputs={filters}
                    values={inputValues}
                    params={params}
                    handleChange={handleChange}
                    handleSearch={handleSearch}
                    clearSearch={clearSearch}
                  />
                </Col>
                {notificaciones.length > 0 && (
                  <Col desktop={12}>
                    <NotificacionPagina
                      mensaje={mensaje}
                      resetNotificaciones={resetNotificaciones}
                    />
                  </Col>
                )}
              </Grid>
            ) : null}

            <Divider />

            <Grid colGap={21} rowGap={21} narrow={true}>
              <Col desktop={2}>
                <CardStats title={"Nuevas solicitudes"} value={data.estadisticas?.nuevas} filter={(e) => handleChangeExternal(e, "tipo_solicitud", 1)} filterText="nuevas"/>
              </Col>
              <Col desktop={2}>
                <CardStats title={"Anulaciones"} value={data.estadisticas?.anuladas} filter={(e) => handleChangeExternal(e, "tipo_solicitud", 2)} filterText="anuladas" />
              </Col>
              <Col desktop={2}>
                <CardStats title={"Endoso"} value={data.estadisticas?.endosos} filter={(e) => handleChangeExternal(e, "tipo_solicitud", 3)} filterText="endosos"/>
              </Col>
              <Col desktop={2}>
                <CardStats title={"Pendientes"} value={data.estadisticas?.pendientes} filter={(e) => handleChangeExternal(e, "estado", "Pendiente Emisión")} filterText="pendientes"/>
              </Col>
              <Col desktop={2}>
                <CardStats title={"Rechazadas"} value={data.estadisticas?.rechazadas} filter={(e) => handleChangeExternal(e, "estado", "Rechazado")} filterText="rechazadas"/>
              </Col>
              <Col desktop={2}>
                <CardStats title={"Prioritarias"} value={data.estadisticas?.prioritarias} filter={(e) => handleChangeExternal(e, "prioridad", 1)} filterText="prioritarias"/>
              </Col>
            </Grid>

            {filtersApplied.length > 0 && params && data.solicitudes
              ? 
              <div className="filtros-aplicados-container">
                <span>{pagination.dataLength} resultados encontrados con los siguientes filtros: </span>
                <div>
                  {filtersApplied.map((item) => {
                      const tiposSolicitud = { 1: "Nueva solicitud", 2: "Anulación por reemplazo", 3: "Endoso" }
                      const nombreFiltro = item[0] 
                      const valorFiltro = item[0] === "tipo_solicitud" ? tiposSolicitud[item[1]] : item[1]
                      return (
                        <span className="filtro-aplicado" onClick={(e) => removeFilter(e, item[0])}>
                          {nombreFiltro.charAt(0).toUpperCase() + nombreFiltro.slice(1).replace("_", " ")}: {valorFiltro} <FiX/>
                        </span>
                      );
                  })}
                </div>
              </div>
              : null}

            <NewTable
              columns={tableData}
              data={data.solicitudes}
              loading={loading}
              order={handleTableOrder}
              orderParams={order}
              pagination={pagination}
              highlight={asignacionModal.solicitudesSeleccionadas}
            />
          </Container>
        </Inner>
      </Wrapper>
    </>
  );
};

export default SolicitudEmisionRefactor;

