import { Container } from "./styled";
// Components
import Wrapper from "../../../components/Wrapper";
import Inner from "../../../components/Inner";
import TitlePageSection from "../../../components/TitlePageSection";
import Sidebar from "../../../components/Sidebar";
import { Col, Grid } from "../../../components/Grid";
import PieChart from "../../../components/PieChart";
import { colores, leerColor } from "../../../constants";
import Divider from "../../../components/Divider";
import NewTable from "../../../components/NewTable";
import { useTable } from "../../../hooks/useTable";
import BarChart from "../../../components/BarChart";
import NewInputSelect from "../../../components/NewInputSelect";
import { useEffect } from "react";
import { useState } from "react";
import axiosRequest from "../../../utils/axiosConfig";
import formatSelectData from "../../../utils/formatSelectData";
import { FiDollarSign, FiTablet, FiUser } from "react-icons/fi";
import Subtitle from "../../../components/Subtitle";
import { getCompanias, getMediosPago } from "../../../services/Rechazos";

const NewEstadisticasCobranzas = () => {
  const { order, handleTableOrder } = useTable();
  const [companias, setCompanias] = useState([]);
  const [formasPago, setFormasPago] = useState([])
  const [estadosFormated, setEstdosFormated] = useState([1,1,1,1]);
  const [operadoresFormated, setOperadoresFormated] = useState([]);
  const [companiaMedioPagoFormated, setCompaniaMedioPagoFormated] = useState([1,1,1,1]);
  const [labelCiaMedioPago, setLabelCiaMedioPago] = useState('COMPANIA - FORMA DE PAGO')
  const [selectCompania, setSelectCompania] = useState([]);
  const [selectFormaPago, setSelectFormaPago] = useState('');
  const [dataPositvosMedios, setDataPositivosMedios] = useState([1,1,1])


  const getEstadisticasCiaMedioPago = async (id_compania, id_medio_pago) => {

    try {
      let {data} = await axiosRequest.get(`/rechazos/estadisticas/compania_medio_pago`,{
        params:{
          id_compania,
          id_medio_pago
        }
      })
      const {pagado, en_gestion, promesa_pago, derivados} = data.companiasMediosPago;
      setCompaniaMedioPagoFormated([
        pagado,
        en_gestion,
        promesa_pago,
        derivados
      ])

      const matchCia = companias.find(c => parseInt(c.value) === parseInt(selectCompania))
      const matchForma = formasPago.find(f => parseInt(f.value) === parseInt(selectFormaPago))
      const label = matchCia.label.toUpperCase() + ' - ' + matchForma.label.toUpperCase()
      setLabelCiaMedioPago(label)
      
    } catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {
    const getEmpresas = async () => {
      try {
        let {data: res} = await axiosRequest.get("/rechazos/estadisticas/positivos_contactos")
        
        const { whataspp, email, telefono } = res.positivosMedios
        setDataPositivosMedios([
          whataspp,
          email,
          telefono
        ])

        let {data} = await axiosRequest.get("/rechazos/estadisticas")
        const {pagado, en_gestion, promesa_pago, derivados} = data.estadosCount;
        setEstdosFormated([
          pagado,
          en_gestion,
          promesa_pago,
          derivados
        ])

        setOperadoresFormated(data.operadoresEstadisticas)

        let allCompanias = await getCompanias();
        allCompanias.unshift({
          label: 'TODAS LAS COMPANIAS',
          key: 0,
          value: 0
        })
        setCompanias(allCompanias);

        let allMediosPagos = await getMediosPago();
        allMediosPagos.unshift({
          label: 'TODOS LOS MEDIOS',
          key: 0,
          value: 0
        },{
          label: 'EFECTIVO',
          key: 'efectivo',
          value: 9999
        })
        setFormasPago(allMediosPagos)


      } catch (error) {
        console.log(error);
      }
    };

    getEmpresas();

  }, []);

  const tableData = [
    {
      header: "Analista",
      name: "analista",
    },
    {
      header: "Pagado",
      name: "resuelto_positivo",
    },
    {
      header: "En Gestión",
      name: "gestion",
    },
    {
      header: "Promesa Pago",
      name: "promesa_pago",
    },
    {
      header: "Derivados",
      name: "resuelto_negativo",
    },
    {
      header: "Efectividad",
      name: "efectividad",
    },
  ];

  const handleSelect1Change = (event) => {
    setSelectCompania(event.target.value);
  };

  const handleSelect2Change = (event) => {
    setSelectFormaPago(event.target.value);
  };

  useEffect(() => {
    if( selectCompania && selectFormaPago ){
      setLabelCiaMedioPago('BUSCANDO')
      setCompaniaMedioPagoFormated([1,1,1,1])
      getEstadisticasCiaMedioPago( selectCompania, selectFormaPago)

    } 
  },[selectCompania, selectFormaPago])

  // useEffect()

  const optionsPagoEmpresa = {
    responsive: true,
    scales: {
      x: {
        stacked: false,
      },
      y: {
        stacked: false,
      },
    },
    plugins: {
      title: {
        display: true,
        text: `Estadísticas de rechazos para Federación Patronal con tarjeta de crédito`,
      },
    },
  };


  const optionsPie = {
    plugins: {
      responsive: true,
      title: {
        display: true,
        text: 'Estado de rechazos',
      },
    },
  };

  return (
    <div>
      <Sidebar />
      <Wrapper>
        <Inner>
          <Container>
            <TitlePageSection
              title="Estadísticas Cobranzas"
              description="Información sobre actividad de rechazos"
            ></TitlePageSection>

            <Divider />

            <Subtitle
                icon={<FiUser />}
                title={`Estado de rechazos`}
                description={
                    "Información sobre el estado de rechazos"
                }
                disableDivider={true}
            />

            <Grid colGap={21} rowGap={21} narrow={false}>
              <Col desktop={12}> 

              {
                estadosFormated && 
                <div className={`total-container pie`}>
                  <PieChart data={ {
                      labels: ["Pagado", "En Gestión", "Promesa Pago", "Derivados"],
                      datasets: [
                        {
                          label: "Cantidad",
                          data: estadosFormated,
                          backgroundColor: [
                            `${leerColor(colores.verde)}`,
                            `${leerColor(colores.gris)}`,
                            `${leerColor(colores.naranja)}`,
                            `${leerColor(colores.rojo)}`,
                          ],
                          hoverOffset: 4,
                        },
                      ],
                    }} optionsData={optionsPie} />
                </div>
              }
                
              </Col>
            </Grid>

            <Divider />

            <Subtitle
                icon={<FiUser />}
                title={`Resueltos Positivos por medio de contacto`}
                description={
                    "Información sobre los cobrados y los medios"
                }
                disableDivider={true}
            />

            <Grid colGap={21} rowGap={21} narrow={false}>
              <Col desktop={12}> 

              {
                estadosFormated && 
                <div className={`total-container pie`}>
                  <PieChart data={ {
                      labels: ["Whatsapp", "Email", "Teléfono"],
                      datasets: [
                        {
                          label: "Cantidad",
                          data: dataPositvosMedios,
                          backgroundColor: [
                            `${leerColor(colores.celesteEstados)}`,
                            `${leerColor(colores.celeste)}`,
                            `${leerColor(colores.celestePasos)}`,
                          ],
                          hoverOffset: 4,
                        },
                      ],
                    }} optionsData={{
                      plugins: {
                        responsive: true,
                        title: {
                          display: true,
                          text: 'Positivos por cada medio',
                        },
                      },
                    }} />
                </div>
              }
                
              </Col>
            </Grid>

            <Divider />

            <Subtitle
                icon={<FiUser />}
                title={`Analistas`}
                description={
                    "Información sobre el rendimiento de cada analista"
                }
                disableDivider={true}
            />

            <NewTable
              columns={tableData}
              data={operadoresFormated}
              order={handleTableOrder}
              orderParams={order}
            />

            <Divider />

            <Grid colGap={21} rowGap={21} narrow={false}>
              <Col desktop={6}>
                <Subtitle
                  icon={<FiDollarSign />}
                  title={`Forma de pago y compañias`}
                  description={
                    "Estadísticas de rechazos según forma de pago y compañia"
                  }
                  disableDivider={true}
                />
              </Col>

              <Col desktop={3}>
                <NewInputSelect
                  name={"id_compania"}
                  placeholder={"Compañia"}
                  id={'compania'}
                  options={companias}
                  onChange={handleSelect1Change}
                />
              </Col>
              <Col desktop={3}>
                <NewInputSelect
                  name={"forma_pago"}
                  placeholder={"Forma de pago"}
                  options={formasPago}
                  onChange={handleSelect2Change}
                />
              </Col>
            </Grid>
          </Container>

          <Grid colGap={21} rowGap={21} narrow={false}>
            <Col desktop={12}>
              <BarChart data={{
                  labels: ["Pagado", "En Gestión", "Promesa Pago", "Derivados"],
                  datasets: [
                    {
                      label: labelCiaMedioPago,
                      data: companiaMedioPagoFormated,
                      backgroundColor: [
                          `${leerColor(colores.verde)}`,
                          `${leerColor(colores.gris)}`,
                          `${leerColor(colores.naranja)}`,
                          `${leerColor(colores.rojo)}`,
                        ],      },
                  ],
                }
              } optionsData={optionsPagoEmpresa} />
            </Col>
          </Grid>
        </Inner>
      </Wrapper>
    </div>
  );
};

export default NewEstadisticasCobranzas;
