import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const Container = styled.div`
  display: inline-block;
  
  // Estados // 
  .emitido, .cerradopositivo-conforme, .cerradopositivo-conformeparcial, .cerradopositivo-disconforme, .resuelto-positivo {
    font-weight: 600;
    background-color: #edfff2;
    color: ${leerColor(colores.verde)};
    border-radius: 20px;
    padding: 6px 12px;
  }
  .notificado {
    font-weight: 600;
    background-color: #faf4ff;
    color: ${leerColor(colores.violeta)};
    border-radius: 20px;
    padding: 6px 12px;
  }
  .pendiente-emisión-sin-asignar{
    font-weight: 600;
    background-color: #fff7ed;
    color: ${leerColor(colores.naranjaEstados)};
    border-radius: 20px;
    padding: 6px 12px;
    position: relative;
    //border:1px solid ${leerColor(colores.naranjaEstados)};
  }
  .pendiente-emisión, .ingresado, .pendiente {
    font-weight: 600;
    background-color: #f6f6f6;
    color: ${leerColor(colores.grisEstados)};
    border-radius: 20px;
    padding: 6px 12px;
    position: relative;
  }
  .emisiónprovisoria, .pendientededocumentación, .pendientedepago, .pendientederesolución, .promesa-de-pago {
    font-weight: 600;
    background-color: #f0faff;
    color: ${leerColor(colores.celeste)};
    border-radius: 20px;
    padding: 6px 12px;
    text-align: left;
    width: min-content;
  }
  .pendienteaprobación, .ofrecimiento{
    font-weight: 600;
    background-color: #ecf0ff;
    color: ${leerColor(colores.azul)};
    border-radius: 20px;
    padding: 6px 12px;
    width: min-content;
  }
  .rechazado, .rechazado-sin-asignar, .cerradonegativo, .resuelto-negativo {
    font-weight: 600;
    background-color: #fff6f6;
    color: ${leerColor(colores.rojo)};
    border-radius: 20px;
    padding: 6px 12px;
    //border:1px solid ${leerColor(colores.rojo)};
  }
  .emisiónprovisoria{
    color: ${leerColor(colores.celesteEstados)};
    font-weight: 700;
  }
  .pendientesuscripción{
    color: ${leerColor(colores.naranjaEstadosSuscripcion)};
    font-weight: 700;
  }
  .pendienteaprobación{
    color: ${leerColor(colores.azul)};
    font-weight: 700;
  }

  .activo{
    font-weight: 600;
    background-color: #edfff2;
    color: ${leerColor(colores.verde)};
    border-radius: 20px;
    padding: 6px 12px;
  }

  .inactivo{
    font-weight: 600;
    background-color: #f6f6f6;
    color: ${leerColor(colores.grisEstados)};
    border-radius: 20px;
    padding: 6px 12px;
  }

  .estado_previo_rechazado {
    position: absolute;
    top: -15px;
    right: 0;
    //background: #fff7ed;
    background: ${leerColor(colores.blanco)};
    border-radius: 100px;
    color: hsl(36.10000000000002,100%,66.1%);
    padding: 4px;
    box-sizing: border-box;
    display: flex;
    border: 1px solid hsl(36.1deg 100% 82.49%);
  }

      // Estados //
      .emisión-sin-curso, .emitido---cambio-pas, .endoso-enviado-a-compañia {
      font-weight: 600;
      background-color: #e1f5ff;
      color: ${leerColor(colores.celeste)};
      border-radius: 20px;
      padding: 6px 12px;
      display: block;
    }
`

