import { useEffect, useState } from "react";
import { Col, Grid } from "../../../Grid";
import NewInputSelect from "../../../NewInputSelect";
import { meses } from "../../../../constants/data";
import Subtitle from "../../../Subtitle";
import {
  FiAlertTriangle,
  FiBarChart,
  FiChevronDown,
  FiDollarSign,
  FiStar,
  FiUser,
} from "react-icons/fi";
import PieChart from "../../../PieChart";
import SpinnerLoading from "../../../SpinnerLoading";
import { useActionButton } from "../../../../hooks/useActionButton";
import LineChart from "../../../LineChart";
import BarChart from "../../../BarChart";
import axiosRequest from "../../../../utils/axiosConfig";
import dayjs from "dayjs";
import { useLoading } from "../../../../hooks/useLoading";
import formatSelectData from "../../../../utils/formatSelectData";
import CardSinResultados from "../../../CardSinResultados";
import Divider from "../../../Divider";

const PremiosEstadisticasTab = ({ data }) => {
  const formatter = new Intl.NumberFormat("es-ES", {});
  const {
    mes,
    setMes,
  } = data;
  const { isActionButtonOpen, handleToggle } = useActionButton();
  const [premiosGanados, setPremiosGanados] = useState([]);
  const [total, setTotal] = useState(null);
  const [dataPie, setDataPie] = useState({});
  const [prima, setPrima] = useState({});
  const [operaciones, setOperaciones] = useState({});
  const [totalPremiosAnual, setTotalPremiosAnual] = useState({});
  const [dataPrimaDiariaProducto, setDataPrimaDiariaProducto] = useState([]);
  const [dataOperacionesDiariasProducto, setDataOperacionesDiariasProducto] =
    useState([]);
  const [productos, setProductos] = useState([]);
  const [productoSeleccionado, setProductoSeleccionado] = useState(1);
  const { loading, setLoading } = useLoading(true);
  const [operacionesPagoNull, setOperacionesPagoNull] = useState(null)
  
  // PRODUCTOS
  useEffect(() => {
    const getProductos = async () => {
      try {
        const productos = await axiosRequest.get("/premios/productos");
        const productosList = formatSelectData(
          productos.data,
          "numero",
          "descripcion",
          "numero"
        );
        setProductos(productosList);
      } catch (error) {
        console.log(error);
      }
    };
    getProductos();
  }, []);

  useEffect(() => {
    const getPremiosGanados = async () => {
      if (mes) {
        try {
          const premiosGanados = await axiosRequest.get(
            `/premios/cantidad_premios_ganados/${parseInt(mes) + 1}`
          );
          setPremiosGanados(premiosGanados.data);
        } catch (error) {
          console.log(error);
        }
      }
    };
    getPremiosGanados();
  }, [mes]);

  // ESTADISTICAS
  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      if (mes && productos.length > 0) {
        try {
          const totalPremiosMes = await axiosRequest.get(`/premios/total`);
          const total = await axiosRequest.get(
            `/premios/vendedores_total/${
              parseInt(mes) + 1
            }/${productoSeleccionado}`
          );
          const historialAnual = await axiosRequest.get(
            `/premios/historial_anual`
          );

          const diasMes = new Date("2024", parseInt(mes) + 1, 0).getDate();
          const labelsDiasMes = Array.from({ length: diasMes }).map(
            (_, index) => {
              const day = index + 1 < 10 ? `0${index + 1}` : index + 1;
              const month =
                parseInt(mes) + 1 < 10 ? `0${parseInt(mes) + 1}` : parseInt(mes) + 1;
              return `${day}/${month}`;
            }
          );

          const labels = [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre",
          ];

          const productoLabel = productos.find(
            (item) => item.value == productoSeleccionado
          )?.label;

          const productosEmitidosPrima = labelsDiasMes.map((label, index) => {
            const find = total.data.productos_emitidos.find((item) => {
              return parseInt(dayjs(item.fechaucac).format("D")) === index + 1;
            });
            if (find) {
              return find.prima;
            } else {
              return 0;
            }
          });
          const productosEmitidosOperaciones = labelsDiasMes.map(
            (label, index) => {
              const find = total.data.productos_emitidos.find((item) => {
                return (
                  parseInt(dayjs(item.fechaucac).format("D")) === index + 1
                );
              });
              if (find) {
                return find.operaciones;
              } else {
                return 0;
              }
            }
          );

          const emisionesPrima = labelsDiasMes.map((label, index) => {
            const find = total.data.emisiones.find((item) => {
              return parseInt(dayjs(item.fechaucac).format("D")) === index + 1;
            });
            if (find) {
              return find.prima;
            } else {
              return 0;
            }
          });
          const emisionesOperaciones = labelsDiasMes.map((label, index) => {
            const find = total.data.emisiones.find((item) => {
              return parseInt(dayjs(item.fechaucac).format("D")) === index + 1;
            });
            if (find) {
              return find.operaciones;
            } else {
              return 0;
            }
          });

          const dataProductosOperaciones = {
            labels: labelsDiasMes,
            datasets: [
              {
                label: productoLabel,
                data: productosEmitidosOperaciones,
                fill: false,
                borderColor: "rgb(0, 255, 179)",
                tension: 0.001,
              },
              {
                label: "Operaciones",
                data: emisionesOperaciones,
                fill: false,
                borderColor: "rgb(26, 86, 255)",
                tension: 0.001,
              },
            ],
          };

          const dataProductosEmitidos = {
            labels: labelsDiasMes,
            datasets: [
              {
                label: productoLabel,
                data: productosEmitidosPrima,
                fill: false,
                borderColor: "rgb(0, 255, 179)",
                tension: 0.001,
              },
              {
                label: "Prima",
                data: emisionesPrima,
                fill: false,
                borderColor: "rgb(26, 86, 255)",
                tension: 0.001,
              },
            ],
          };

          const dataTotalPremios = {
            labels,
            datasets: [
              {
                label: "Total a pagar",
                data: totalPremiosMes.data.map((item) =>
                  Math.round(parseInt(item))
                ),
                backgroundColor: "#1A56FF",
              },
            ],
          };
          const dataPrima = {
            labels,
            datasets: [
              {
                label: "Objetivo prima",
                data: historialAnual.data.map((item) => item.objetivo_prima),
                backgroundColor: "#c4d4ff",
              },
              {
                label: "Prima obtenida",
                data: historialAnual.data.map((item) => Math.round(item.prima)),
                backgroundColor: "#1A56FF",
              },
            ],
          };
          const dataOperaciones = {
            labels,
            datasets: [
              {
                label: "Objetivo operaciones",
                data: historialAnual.data.map(
                  (item) => item.objetivo_operaciones
                ),
                backgroundColor: "#c4d4ff",
              },
              {
                label: "Operaciones realizadas",
                data: historialAnual.data.map((item) => item.operaciones),
                backgroundColor: "#1A56FF",
              },
            ],
          };

          const dataPie = {
            labels: total.data.forma_pago.map((item) => {
              const porcentaje = ((item.operaciones * 100) / total.data.total.operaciones).toFixed(2)
              return `${item.forma_pago} (${porcentaje}%)`
            }),
            datasets: [
              {
                label: "Operaciones emitidas: ",
                data: total.data.forma_pago.map((item) => item.operaciones),
                backgroundColor: [
                  "rgb(26, 86, 255)",
                  "rgb(116, 255, 227)",
                  "rgb(125, 188, 255)",
                ],
                hoverOffset: 4,
              },
            ],
          };

          const operacionesFormaPagoNull = total.data.operacionesPagoNull ? total.data.operacionesPagoNull.map(item => item.numero) : []
          setOperacionesPagoNull(operacionesFormaPagoNull)
          setDataOperacionesDiariasProducto(dataProductosOperaciones);
          setDataPrimaDiariaProducto(dataProductosEmitidos);
          setTotalPremiosAnual(dataTotalPremios);
          setDataPie(dataPie);
          setPrima(dataPrima);
          setOperaciones(dataOperaciones);
          setTotal(total.data);
          setLoading(false);
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      }
    };
    getData();
  }, [mes, productoSeleccionado, productos]);

  return (
    <>
      <Grid colGap={21} rowGap={21} narrow={false}>
        <Col desktop={2}>
          <NewInputSelect
            name={"mes"}
            labelName={"Mes"}
            onChange={(e) => setMes(e.target.value)}
            placeholder={"Mes"}
            options={meses}
            value={mes}
          />
        </Col>
      </Grid>
      <Divider />

      <div className="section-data">
      <Subtitle
        icon={<FiStar />}
        title={`Estadísticas totales de ${meses
          .find((item) => item.value == mes)
          ?.label.toLowerCase()}`}
        description={
          "Prima total obtenida por todos los vendedores y cantidad total de operaciones realizadas"
        }
        disableDivider={true}
      />
      </div>
      {!loading ? (
        <Grid colGap={21} rowGap={21} narrow={false}>
          {total ? (
            <>
              <Col desktop={3}>
                <div className={`total-container`}>
                  <span>Prima total del mes</span>
                  <h2>${formatter.format(Math.round(total.total.prima))}</h2>
                </div>
              </Col>
              <Col desktop={3}>
                <div className={`total-container`}>
                  <span>Cantidad de operacione del mes</span>
                  <h2>{total.total.operaciones}</h2>
                </div>
              </Col>
              {dataPie ? (
                <Col desktop={12}>
                  <div className={`total-container pie`}>
                    <PieChart data={dataPie} />
                  </div>
                </Col>
              ) : null}
              {dataPie && operacionesPagoNull.length > 0 ?
                <Col desktop={12}>
                  <span className="aclaracion-operaciones"><span className="icon"><FiAlertTriangle />
                  </span>Las siguientes operaciones tienen un medio de pago desconocido en la base de datos: <strong>{operacionesPagoNull.join(", ")}</strong>.</span>
                </Col>
              : null}
            </>
          ) : null}
        </Grid>
      ) : (
        <SpinnerLoading text={"Cargando estadísticas del mes"} />
      )}

      <Divider />
      <div className="section-data">
      <Subtitle
        icon={<FiDollarSign />}
        title={`Premios ganados en ${meses
          .find((m) => m.value == mes)
          ?.label.toLocaleLowerCase()}`}
        description={
          "Lista de la cantidad de premios que fueron ganados por los vendedores"
        }
        disableDivider={true}
      />
      </div>
      {!loading ? (
        <>
          {premiosGanados.length > 0 ? (
            <Grid colGap={21} rowGap={21} narrow={false}>
              {premiosGanados.map((item, index) => {
                return (
                  <Col desktop={12}>
                    <div className="grupo-container">
                      <div className="header-premio-container">
                        <div>
                          <h3>{item.nombre_premio}</h3>
                          <span>
                            {item.cantidad_vendedores > 1
                              ? `${item.cantidad_vendedores} vendedores ganaron el premio`
                              : `1 vendedor ganó el premio`}
                          </span>
                        </div>
                        <span
                          className="chevron-vendedores"
                          onClick={() => handleToggle(index)}
                        >
                          Ver vendedores{" "}
                          <span className="icono-chevron">
                            <FiChevronDown />
                          </span>
                        </span>
                      </div>
                      {isActionButtonOpen === index ? (
                        <div className="vendedores-container">
                          <Grid colGap={21} rowGap={21} narrow={false}>
                            {item.vendedores.length > 0 ? (
                              item.vendedores.map((vendedor, index) => {
                                return (
                                  <Col desktop={3}>
                                    <div className="vendedor">
                                      <span className="userIcon">
                                        <FiUser />
                                      </span>
                                      <div className="integrante-data">
                                        <span className="nombre">
                                          {vendedor.nombre_vendedor}
                                        </span>
                                        <div>
                                          <span className="integrante-prima-actual">
                                            $
                                            {formatter.format(
                                              vendedor.valor_premio
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                );
                              })
                            ) : (
                              <span>Ningún vendedor ganó este premio</span>
                            )}
                          </Grid>
                        </div>
                      ) : null}
                    </div>
                  </Col>
                );
              })}
            </Grid>
          ) : null}
          {!loading && premiosGanados.length === 0 ? (
            <CardSinResultados icon={<FiStar />} title={"No hay premios ganados"} description={"No se ganaron premios durante el mes"}/>
          ) : null}
        </>
      ) : (
        <SpinnerLoading text={"Cargando estadísticas del mes"} />
      )}

      <Divider />
      <div className="section-data">
      <Subtitle
        icon={<FiStar />}
        title={`Prima y operaciones diarias de ${productos
          .find((item) => item.value == productoSeleccionado)
          ?.label.toLowerCase()} en ${meses
          .find((item) => item.value == mes)
          ?.label.toLowerCase()}`}
        description={
          "Prima obtenida de forma diaria para el producto seleccionado durante el mes"
        }
        disableDivider={true}
      />
      {!loading ? (
        <Grid colGap={21} rowGap={21} narrow={false}>
          {total ? (
            <>
              <Col desktop={2}>
                <NewInputSelect
                  name={"producto"}
                  labelName={"Producto"}
                  onChange={(e) => setProductoSeleccionado(e.target.value)}
                  placeholder={"Producto"}
                  options={productos}
                  value={productoSeleccionado}
                />
              </Col>
              {dataPrimaDiariaProducto ? (
                <Col desktop={12}>
                  <LineChart
                    data={dataPrimaDiariaProducto}
                    options={{
                      responsive: true,
                      scales: {
                        x: {
                          stacked: false,
                        },
                        y: {
                          stacked: false,
                        },
                      },
                      plugins: {
                        title: {
                          display: true,
                          text: "Prima diaria",
                        },
                      },
                    }}
                  />
                </Col>
              ) : null}
            </>
          ) : null}
        </Grid>
      ) : (
        <SpinnerLoading text={"Cargando estadísticas del mes"} />
      )}
      <Divider />
      {!loading ? (
        <Grid colGap={21} rowGap={21} narrow={false}>
          {total ? (
            <>
              {dataOperacionesDiariasProducto ? (
                <Col desktop={12}>
                  <LineChart
                    data={dataOperacionesDiariasProducto}
                    options={{
                      responsive: true,
                      scales: {
                        x: {
                          stacked: false,
                        },
                        y: {
                          stacked: false,
                        },
                      },
                      plugins: {
                        title: {
                          display: true,
                          text: "Operaciones emitidas por día",
                        },
                      },
                    }}
                  />
                </Col>
              ) : null}
            </>
          ) : null}
        </Grid>
      ) : (
        <SpinnerLoading text={"Cargando estadísticas del mes"} />
      )}
      </div>

      {Object.keys(prima).length > 0 ? (
        <>
          <Divider />
          <div className="section-data">
          <Subtitle
            icon={<FiBarChart />}
            title={"Prima anual"}
            description={`Evolución de la prima obtenida a lo largo del año`}
            disableDivider={true}
          />
          <Grid colGap={21} rowGap={21} narrow={false}>
            <Col desktop={12}>
              <BarChart data={prima} />
            </Col>
          </Grid>
          </div>
        </>
      ) : null}
      <Divider />

      {Object.keys(operaciones).length > 0 ? (
        <>
          <div className="section-data">
          <Subtitle
            icon={<FiBarChart />}
            title={"Operaciones anuales"}
            description={`Evolución de la cantidad de operaciones realizadas a lo largo del año`}
            disableDivider={true}
          />
          <Grid colGap={21} rowGap={21} narrow={false}>
            <Col desktop={12}>
              <BarChart data={operaciones} />
            </Col>
          </Grid>
          </div>
        </>
      ) : null}
      <Divider />

      {Object.keys(totalPremiosAnual).length > 0 ? (
        <>
          <div className="section-data">
          <Subtitle
            icon={<FiBarChart />}
            title={"Total a pagar de premios"}
            description={`Evolución del total de premios a pagar a vendedores por mes.`}
            disableDivider={true}
          />
          <Grid colGap={21} rowGap={21} narrow={false}>
            <Col desktop={12}>
              <BarChart data={totalPremiosAnual} />
            </Col>
          </Grid>
          </div>
        </>
      ) : null}
    </>
  );
};

export default PremiosEstadisticasTab;
