import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../../constants";

export const ContainerEstados = styled.div`
  //margin: 20px 0;
  //border: 1px solid #eaeaea;
  padding: 0 0 10px 0;
  color: ${leerColor(colores.grisEstados)};
  border-radius: 6px;

  .header-estado {
    color: ${leerColor(colores.grisEstados)};
  }
`;

export const ContainerFile = styled.div`
  img {
    width: 100%;
    height: 160px;
    object-fit: cover;
    border-radius: 6px;
    border: 1px solid #eaeaea;
    margin-top: 10px;
  }

  label {
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    color: #202020;
    font-weight: 500;
  }

  .icon {
    width: 100%;
    height: 160px;
    font-size: 100px;
    display: flex;
    align-items: center;
    color: ${leerColor(colores.azulRebranding)};
    justify-content: center;
    border-radius: 6px;
    border: 1px solid #eaeaea;
    margin-top: 10px;
    box-sizing: border-box;
  }
  /*
  width: 100px;
  height: 100px;
  font-size: 60px;
  display: flex;
  align-items: center;
  color: ${leerColor(colores.azulRebranding)};
  justify-content: center;
  */
`;

export const Prioridad = styled.div`
    background-color: ${leerColor(colores.negro)};
    color: ${leerColor(colores.blanco)};
    width: fit-content;
    border-radius: 8px;
    padding: 4px 10px;
    box-sizing: border-box;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 14px;
    margin-bottom: 10px;
`;


export const EmptyDocumentacion = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    border: 1px solid #eaeaea;
    box-sizing: border-box;
    padding: 80px 0;
    border-radius: 8px;

    .icon {
      font-size: 60px;
      color: ${leerColor(colores.azulRebranding)};
      display: flex;
    }

    h3 {
      margin: 0;
      font-size: 20px;
      color: ${leerColor(colores.negro)};
      font-weight: 500;
    }

    .descripcion {
      color: ${leerColor(colores.grisEstados)};
      font-size: 14px;
    }
`;

export const ModificacionHistorial = styled.div`
  display: flex;
  gap: 10px;
  //margin-bottom: 30px;

  .icono {

    span {
      display: flex;
    border: 1px solid #eaeaea;
    font-size: 16px;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 100px;
    color: #1a56ff;
    }

  }  

  .modificacion {
  /*
    display: flex;
    flex-direction: column;
    gap: 14px;
    */
  }

  .notificacion-sin-comentario {
    display: flex;
    align-content: center;
    gap: 6px;
  }

  .modificacion-hora {
    font-size: 12px;
  }

  .modificacion-titulo .icon {
    display: flex;
    align-items: center;
  }

  span {
    color: ${leerColor(colores.grisEstados)};
    font-size: 14px;

    strong {
      color: ${leerColor(colores.negro)};
      font-weight: 500;
    }

    .vendedor {
        color: ${leerColor(colores.azulRebranding)};
        cursor: pointer;
    }
  }

  .userIcon {
    display: flex;
    border: 1px solid #eaeaea;
    font-size: 16px;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 100px;
    color: #1a56ff;
    margin-right: 8px;
  }

  .calculo-container {
    display: flex;
    border: 1px solid #eaeaea;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 20px;
    line-height: 1.7;
    align-items: center;
    gap: 6px;
    margin: 10px 0;

    p {
      font-size: 14px;
      margin: 0;
    }
  }

  .estado {
    font-weight: 600;
    border-radius: 20px;
    padding: 6px 12px;
    position: relative;
  }

  .notificado, .contactado {
    background-color: #faf4ff;
    color: ${leerColor(colores.violeta)};
  }
  
  .promesa-de-pago {
    background-color: ${leerColor(colores.naranjaNot)};
    color: ${leerColor(colores.naranjaEstados)};
  }
  
  .resuelto-positivo {
    background-color: #edfff2;
    color: ${leerColor(colores.verde)};
  }

  .resuelto-negativo {
    background-color: #fff6f6;
    color: ${leerColor(colores.rojo)};
  }

  .pendiente {
    background-color: #f6f6f6;
    color: ${leerColor(colores.grisEstados)};
  }

  .promesa-de-pago {
    background-color: #f0faff;
    color: ${leerColor(colores.celeste)};
  }
`;

export const ContainerHistorial = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  .subtitulo-historial {
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    margin: 0 0 10px 0;
  }
`;  
