import LaSegundaLogo from "../assets/images/logos/laSegunda.svg"
import ExpertaLogo from "../assets/images/logos/expertaSeguros.svg"
import ExpertaARTLogo from "../assets/images/logos/expertaART.svg"
import HDILogo from "../assets/images/logos/hdiSeguros.svg"
import FedPatLogo from "../assets/images/logos/federacionPatronal.svg"
import PrudenciaLogo from "../assets/images/logos/prudencia.png"
import ColonLogo from "../assets/images/logos/colonSeguros.svg"
import ProvinciaLogo from "../assets/images/logos/provinciaSeguros.svg"
import RUSLogo from "../assets/images/logos/rioUruguay.svg"
import ATMLogo from "../assets/images/logos/atmSeguros.svg"
import NacionLogo from "../assets/images/logos/nacionSeguros.svg"
import SanCristobalLogo from "../assets/images/logos/sanCristobal.svg"
import AllianzLogo from "../assets/images/logos/allianz.svg"
import ParanaLogo from "../assets/images/logos/paranaSeguros.svg"
import MapfreLogo from "../assets/images/logos/mapfre.svg"
import IntegrityLogo from "../assets/images/logos/integritySeguros.svg"
import PlenariaLogo from "../assets/images/logos/plenariaSeguros.svg"
import PrevencionARTLogo from "../assets/images/logos/prevencionART.svg"
import SancorLogo from "../assets/images/logos/sancorSeguros.svg"
import LibraSeguros from "../assets/images/logos/libraSeguros.svg"

export const LOGOS_COMPANIAS = [
  {
    nombre: "LA SEGUNDA COOP.SEG.GRALES.",
    className: "lasegunda",
    url: LaSegundaLogo,
  },
  {
    nombre: "LA SEGUNDA",
    className: "lasegunda",
    url: LaSegundaLogo,
  },
  {
    nombre: "La Segunda",
    className: "lasegunda",
    url: LaSegundaLogo,
  },
  {
    nombre: "EXPERTA ART",
    className: "experta",
    url: ExpertaARTLogo,
  },
  {
    nombre: "Experta Art",
    className: "experta",
    url: ExpertaARTLogo,
  },
  {
    nombre: "Experta ART",
    className: "experta",
    url: ExpertaARTLogo,
  },
  {
    nombre: "EXPERTA SEGUROS S.A.",
    className: "experta",
    url: ExpertaLogo,
  },
  {
    nombre: "HDI SEGUROS S.A.",
    className: "hdi",
    url: HDILogo,
  },
  {
    nombre: "HDI SEGUROS S.A.",
    className: "hdi",
    url: HDILogo,
  },
  {
    nombre: "HDI SEGUROS",
    className: "hdi",
    url: HDILogo,
  },
  {
    nombre: "FEDERACION PATRONAL",
    className: "fedpat",
    url: FedPatLogo,
  },
  {
    nombre: "PRUDENCIA-BICICLETAS",
    className: "prudencia",
    url: PrudenciaLogo,
  },
  {
    nombre: "Prudencia",
    className: "prudencia",
    url: PrudenciaLogo,
  },
  {
    nombre: "PRUDENCIA seguros",
    className: "prudencia",
    url: PrudenciaLogo,
  },
  {
    nombre: "PRUDENCIA-AGENCIAS",
    className: "prudencia",
    url: PrudenciaLogo,
  },
  {
    nombre: "PRUDENCIA-BICICLETAS",
    className: "prudencia",
    url: PrudenciaLogo,
  },
  {
    nombre: "LIBRA CIA.ARG.DE SEG.S.A.",
    className: "libra",
    url: LibraSeguros,
  },
  {
    nombre: "Colon",
    className: "colon",
    url: ColonLogo,
  },
  {
    nombre: "COLON CIA.DE SEG.S.A.",
    className: "colon",
    url: ColonLogo,
  },
  {
    nombre: "ATM",
    className: "atm",
    url: ATMLogo,
  },
  {
    nombre: "NACION SEGUROS S.A.",
    className: "nacion",
    url: NacionLogo,
  },
  {
    nombre: "NACION SEGUROS",
    className: "nacion",
    url: NacionLogo,
  },
  {
    nombre: "Nacion",
    className: "nacion",
    url: NacionLogo,
  },
  {
    nombre: "ORBIS CIA.ARG.DE SEG.",
    className: "orbis",
    url: "https://gys.com.ar/images/logos/aseguradoras/segurosOrbis.svg",
  },
  {
    nombre: "RIO URUGUAY SEGUROS",
    className: "rus",
    url: RUSLogo,
  },
  {
    nombre: "Rio Uruguay",
    className: "rus",
    url: RUSLogo,
  },
  {
    nombre: "PROVINCIA SEGUROS S.A.",
    className: "provincia",
    url: ProvinciaLogo,
  },
  {
    nombre: "PROVINCIA SEGUROS",
    className: "provincia",
    url: ProvinciaLogo,
  },
  {
    nombre: "Provincia",
    className: "provincia",
    url: ProvinciaLogo,
  },
  {
    nombre: "Provincia ART",
    className: "provincia",
    url: ProvinciaLogo,
  },
  {
    nombre: "PROVINCIA ART",
    className: "provincia",
    url: ProvinciaLogo,
  },
  {
    nombre: "San Cristobal",
    className: "sancristobal",
    url: SanCristobalLogo,
  },
  {
    nombre: "SAN CRISTOBAL",
    className: "sancristobal",
    url: SanCristobalLogo,
  },
  {
    nombre: "SAN CRISTOBAL SEGUROS",
    className: "sancristobal",
    url: SanCristobalLogo,
  },
  {
    nombre: "Allianz",
    className: "allianz",
    url: AllianzLogo,
  },
  {
    nombre: "ALLIANZ CIA.DE",
    className: "allianz",
    url: AllianzLogo,
  },
  {
    nombre: "Experta",
    className: "experta",
    url: ExpertaLogo,
  },
  {
    nombre: "EXPERTA SEGUROS",
    className: "experta",
    url: ExpertaLogo,
  },
  {
    nombre: "Parana",
    className: "parana",
    url: ParanaLogo,
  },
  {
    nombre: "PARANA S.A.DE SEGUROS",
    className: "parana",
    url: ParanaLogo,
  },
  {
    nombre: "Mapfre",
    className: "mapfre",
    url: MapfreLogo,
  },
  {
    nombre: "MAPFRE ARGENTINA",
    className: "mapfre",
    url: MapfreLogo,
  },
  {
    nombre: "Integrity",
    className: "integrity",
    url: IntegrityLogo,
  },
  {
    nombre: "INTEGRITY SEGUROS",
    className: "integrity",
    url: IntegrityLogo,
  },
  {
    nombre: "Plenaria",
    className: "plenaria",
    url: PlenariaLogo,
  },
  {
    nombre: "PLENARIA",
    className: "plenaria",
    url: PlenariaLogo,
  },
  {
    nombre: "Prevención ART",
    className: "prevencion",
    url: PrevencionARTLogo,
  },
  {
    nombre: "PREVENCION ART",
    className: "prevencion",
    url: PrevencionARTLogo,
  },
  {
    nombre: "Sancor",
    className: "sancor",
    url: SancorLogo,
  },
  {
    nombre: "SANCOR",
    className: "sancor",
    url: SancorLogo,
  },
];
