import axios from 'axios'
import axiosRequest from '../utils/axiosConfig'
import dayjs from 'dayjs'
import formatSelectData from "../utils/formatSelectData";

//trae los datos del back
const getRechazos = async (filter,order,page) => {
    const urlFilter = filter? '&'+filter: ''
    const urlOrder = order? '&'+order: ''
    const url = `/rejects?page_req=${page}`+urlFilter+urlOrder;

    try {
        const send = await axiosRequest.get(url); 
        const operadores = await axiosRequest.get('operadores')
        const rechazos = send.data.msg.map(item => {
          const operador = operadores.data.find(operador => operador.id_usuario === item.id_user)
          return { ...item, nombre_usuario: `${operador.nombre_operador} ${operador.apellido_operador}`}
        })
        const companias = await getCompanias();
        const rechazosFormated = rechazos.map( rej => {
            if(companias.length>0){
              const compania = companias.find( c => c.key === rej.id_compania)
              rej.compania = compania.label // agrega el nombre de la compania
              rej.createdAt = dayjs(rej.createdAt).format('DD/MM/YYYY')
              rej.updatedAt = dayjs(rej.updatedAt).format('DD/MM/YYYY')
              rej.estado = rej.Rejects_estado.estado
            }
            return rej
          })
        return { 
            rechazos: rechazosFormated, // datos de los rechazos
            pagination_res: send.data.paginationData, // datos de la paginacion
            estadisticas: send.data.estadisticas
        };   

    } catch (error) {
        if(error.response.status === 300){
            return { rechazos: [], pagination_res: {}, error:'No existen datos con los filtros seleccionados'};
        }
        return { rechazos: [], pagination_res: {}, error:'Error al obtener datos del servidor'};
    }
}

//trae los detalles del rechazo desde el back
const getRechazoById = async (id) =>{
    try {
        const send = await axiosRequest.get(`/rejects/${parseInt(id)}`)
        return send.data.msg;
    } catch (error) {
        return new Error('Error al obtener detalles')
    }
    
}

//trae el historial del rechazo
const getRechazoHistorial = async (id) =>{
    try {
        const send = await axiosRequest.get(`/rejects/historial/${parseInt(id)}`)
        return send.data.msg;
    } catch (error) {
        return new Error('Error al obtener detalles')
    }
    
}

// obtiene los datos de las polizas
const getInforPolizas = async (polizas, companiaCsv, toUpdate) => {
    try {
        const res = await axiosRequest({
            url: "/rejects/polizas",
            method: "post",
            data: { polizas, companiaCsv, toUpdate },
          });
        return res;
    } catch (error) {
        console.log(error)
    }
}

//envia el csv formateado al back
const sendData = async (data, id_user) => {
    try {
        const { msg , data: response, status } = await axiosRequest.post(`/rejects`, data, { params: {id_user}})
        if(status === 200 || status === 201){
            return {type:'success', msg, data: response}
        }
        
    } catch (error) {
        if(error.response.status === 300){
            return {type:'error', data: [], msg:'El archivo contiene algunos datos con formato inválido'}
        }
        if(error.response.status === 301){
            return {type:'error', data: [], msg:'Existen pólizas duplicadas'}
        }
        if(error.response.status === 302){
            return {type:'error', data: [], msg:'Error al leer el archivo, recarge la página e intente nuevamente'}
        }
        return  {type:'error', data: [], msg:'Error al ingresar Csv, intente nuevamente mas adelante'} 
    }
}


//cambia el estado del rechazo 
// newstate -> objeto literal
// FUNCIÓN NUEVA ----- REVISAR 
const updateReject = async (id, data, id_user)=>{
    try {
        // envia
        const {status, data: res } = await axiosRequest.put(`/rejects/${id}`, data, {params: {id_user}})

        if(status === 200 || status === 201){
            return  {type:'success', msg:res.msg , data:res}
        }
    } catch (error) {
        return  {type:'error', msg:'Error no se ingresaron los cambios'}
    } 
}

const updateMultipleRejects = async (data, id_user)=>{
    try {
        // envia
        const {status, data: res} = await axiosRequest.put(`/all_rejects/`, data, {params: {id_user}})
        if(status === 200 || status === 201){
            return  {type:'success', msg :' actualizado correctamente' , data:res}
        }
    } catch (error) {
        return  {type:'error', msg:'Error no se ingresaron los cambios'}
    } 
}



//Borra un rechazo (borrado logico)

const deleteReject = async (id)=>{
    try {
        const send = await axiosRequest.delete(`/rejects/${id}`)
        if(send.status === 200 || send.status === 201){
            return  {type:'success', msg:'Rechazo borrado correctamente'}
        }
    } catch (error) {
        return  {type:'error', msg:'Error de red, no se ingresaron los cambios'}
    } 
}

// Borra masivamente los rechazos segun el estado
// SOLO HABILITADO PARA RECHAZOS PENDIENTES por el momento
const deleteRejecstByState = async (state)=>{
    try {
        const send = await axiosRequest.delete(`/all_rejects/${state}`)
        if(send.status === 200 || send.status === 201){
            return  {type:'success', msg: send.data.msg}
        }
    } catch (error) {
        return  {type:'error', msg: error.data.msg}
    } 
}


const downloadCsv = async (filter, order,companias)=>{
    const urlFilter = filter? '&'+filter: ''
    const urlOrder = order? '&'+order: ''
    const url = `/download?`+urlFilter+urlOrder;

    try {
        const {status, data} = await axiosRequest(
            {
                url: url,
                method: "POST",
                responseType: "blob", // importante
                data:companias
        })
        if(status === 200 || status === 201){
            let url;
            var isChrome = navigator.userAgent.indexOf("Chrome") != -1;
            if ( isChrome ){
                url = window.webkitURL.createObjectURL(new Blob([data]));
            } else {
                url = window.URL.createObjectURL(new Blob([data]));
            }
            const link = document.createElement("a");
            link.href = url;
            const fechaactual = dayjs(new Date()).format('YYYY_MM_DD')
            const filename = `informe_rechazos_${fechaactual}.csv`
            link.setAttribute("download", filename);
            document.body.appendChild(link)
            link.click();
       }
       return new Error(data.error)
    } catch (error) {
        return new Error('Error al obtener csv desde el servidor')
    }
    
}

//cambia el estado del rechazo 
const getStatusMails = async ()=>{
    try {
        const send = await axios.get('/status_mails');
        const {msg} = send.data
        if(send.status === 200){
            return msg;
        }
        if(send.status === 300 || send.status === 500){
            return false;
        }
    } catch (error) {
        return error;
    } 
}

const sendMailManually = async (arreglo, id_user)=>{
    try {
        const send = await axiosRequest.post('/send_mail',arreglo, {params:{id_user}});
        const {msg, data} = send.data
        if(send.status === 200){
            return { type:'success', msg, data}
        }
    } catch (error) {
        return { type:'error', msg: "error al enviar notificaciones" }
    } 
}

const getHistorial = async (id)=>{
    try {
        const send = await axiosRequest.get(`/rejects/historial/${id}`);
        const {msg} = send.data
        if(send.status === 200){
            return msg;
        }
        if(send.status === 300 || send.status === 500){
            return false;
        }
    } catch (error) {
        return error;
    } 
}

const getContactsForUser = async() => {
    try {
        const send = await axiosRequest({
          url: "reject/routes_contact",
          method: "get",
        });
        return send.data.msg;
      } catch (error) {
        return [];
      }
}

const newContact = async(data) => {
    try {
        const result = await axiosRequest.post("/reject/contact",data);
        return result
      } catch (error) {
        return [];
      }
}


const getEstados = async () => {
    try {
      const result = await axiosRequest({
        url: "/estados_rej?search=estados",
        method: "get",
      });
      return formatSelectData(result.data.msg, 'id', 'estado','id' )
    } catch (error) {
      return [];
    }
  }

  const getSecciones = async () => {
    try {
      const result = await axiosRequest({
        url: "/secciones_rej?search=secciones",
        method: "get",
      });
      return formatSelectData(result.data.msg, 'seccion', 'seccion','id' )
    } catch (error) {
      return [];
    }
  }

  const getCompanias = async () => {
    try {
        const send = await axiosRequest.get(`/companias_rej?search=all`);
        if (send.status === 200 || send.status === 201) {
            return formatSelectData(send.data.msg, 'numero', 'nombre','numero' )
        }
    } catch (error) {
      return [];
    }
  }

  const getMediosPago= async () => {
    try {
        const send = await axiosRequest.get(`/medios_pago?search=medios`);
        if (send.status === 200 || send.status === 201) {
            return formatSelectData(send.data.msg, 'id', 'nombre','id' )
        }
    } catch (error) {
      return [];
    }
  }

  const getOperadores = async () => {
    try {
        const operadores = await axiosRequest.get('operadores')
        const filterOperadores = operadores.data.filter(operador => operador.id_rol === 8 && operador.estado_usuario)
        return formatSelectData(filterOperadores, 'id_usuario',  ['nombre_operador', 'apellido_operador'], 'id_usuario')
    } catch (error) {
        return [];
    }
  }

export {
    getRechazos,
    getRechazoById,
    sendData,
    updateReject,
    downloadCsv,
    getStatusMails,
    sendMailManually,
    deleteReject,
    getHistorial,
    getEstados,
    getSecciones,
    deleteRejecstByState,
    updateMultipleRejects,
    getCompanias,
    getInforPolizas,
    getRechazoHistorial,
    getContactsForUser,
    getOperadores,
    getMediosPago
}